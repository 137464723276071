import React, { useReducer } from "react";
import axios from "axios";
import PostContext from "./postContext";
import postReducer from "./postReducer";
import {
  ADD_POST,
  REMOVE_POST,
  POST_ERROR,
  GET_POSTS,
  SET_CURRENT,
  UPDATE_POST,
  CLEAR_POSTS,
  CLEAR_CURRENT,
} from "../types";

const PostState = (props) => {
  const initialState = {
    posts: null,
    current: null,
    error: null,
  };

  const [state, dispatch] = useReducer(postReducer, initialState);

  const addPost = async (post) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=XXX',
      },
    };
    const form = new FormData();
    for (let key in post) {
      form.append(key, post[key]);
    }
    for (var key of form.entries()) {
      console.log(key[0] + ', ' + key[1]);
  }
    try {
      const res = await axios.post("/api/posts", form, config);

      console.log("res", res);
      dispatch({ type: ADD_POST, payload: res });
    } catch (error) {
      dispatch({ type: POST_ERROR, payload: error.response.msg });
    }
  };
  const getPosts = async (id) => {
    try {
      const res = await axios.get('/api/posts');
      dispatch({ type: GET_POSTS, payload: res.data });
    } catch (error) {
      dispatch({ type: POST_ERROR, payload: error.response.msg });
    }
  };
  const getPostsByUser = async (id) => {
    try {
      const res = await axios.get(`/api/posts/user/${id}`);
      dispatch({ type: GET_POSTS, payload: res.data });
    } catch (error) {
      dispatch({ type: POST_ERROR, payload: error.response.msg });
    }
  };
  const setCurrent = (post) => {
    dispatch({ type: SET_CURRENT, payload: post });
  };
  const updatePost = async (id, newPostFields) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const form = new FormData();
    for (let key in newPostFields) {
      form.append(key, newPostFields[key]);
    }
    try {
      const res = await axios.put(`/api/posts/${id}`, form, config);
      dispatch({ type: UPDATE_POST, payload: res.data });
    } catch (error) {
      dispatch({ type: POST_ERROR, payload: error.response.msg });
    }
  };

  const deletePost = async (id) => {
    try {
      await axios.delete(`/api/posts/${id}`);
      dispatch({ type: REMOVE_POST, payload: id });
    } catch (error) {
      dispatch({ type: POST_ERROR, payload: error.response.msg });
    }
  };
  const getPost = async (id) => {
    try {
      const res = await axios.get("/api/posts/" + id);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };
  const clearPosts = () => {
    dispatch({ type: CLEAR_POSTS });
  };

  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  return (
    <PostContext.Provider
      value={{
        posts: state.posts,
        current: state.current,
        error: state.error,
        dispatch,
        addPost,
        getPost,
        getPosts,
        deletePost,
        updatePost,
        getPostsByUser ,
        clearPosts,
        setCurrent,
        clearCurrent,
      }}
    >
      {props.children}
    </PostContext.Provider>
  );
};

export default PostState;
