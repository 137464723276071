import React, { useEffect, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  listUsers } from "../actions/userActions";
import UserCard from "./UserCard";
import { USER_DETAILS_RESET } from "../constants/userConstants";


export default function UserListScreen(props) {
 

  const userList = useSelector((state) => state.userList);
  const { users } = userList;



  const dispatch = useDispatch();
  const [isPending, startTransition]= useTransition();
  useEffect(() => {
    startTransition(()=>{   dispatch(listUsers());
    dispatch({
      type: USER_DETAILS_RESET,
    });
  });
  }, [dispatch]);

  return (
    isPending ?(<div>Loading...</div>)
    : (
       
          <div className="flex justify-between items-center py-5 px-2 ">
            <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
              {users &&
                users.map((user) => (
                 
                    <UserCard key={user._id} user={user} />
                 
                ))}
            </div>
          </div>
       
      )
   
  );
}
