import React, { useState } from "react";

import TaskForm from "../tasks/TaskForm";
import TasksList from "../tasks/TasksList";

function Tasks() {
  const [showTasksDone, setShowTasksDone] = useState(true);

  return (
    <div className=" m-5 px-2 py-3 mt-8 shadow-md ">
        <div className="grid  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 ">
      <TaskForm />
      <header className="flex justify-between items-center">    
          <button
            onClick={() => setShowTasksDone(!showTasksDone)}
            className="bg-purple-700 hover:bg-purple-600 btn text-white focus:outline-none disabled:bg-blue-400 "
          >
            {showTasksDone ? "Show tasks done" : "Show pending tasks"}
          </button>        
        </header>
        <h5 className="text-md text-gray-300 ">
           <p> {showTasksDone ? " Pending  tasks..." : ` Tasks done..`}</p>
          </h5>   
          </div>
      <TasksList doneState={showTasksDone} />
    </div>
  );
}

export default Tasks;
