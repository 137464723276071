import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser, updateUserProfile } from "../actions/userActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

export default function ProfileScreen() {
 
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser(userInfo._id));
    } else {
      setName(user.name);
      setEmail(user.email);
     
    }
  }, [dispatch, userInfo._id, user]);
  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch update profile
    if (password !== confirmPassword) {
      alert("Password and Confirm Password Are Not Matched");
    } else {
      dispatch(
        updateUserProfile({
          userId: user._id,
          name,
          email,
          password,
        })
      );
    }
  };

  return (
    <div className="bg-zinc-800 p-10 mt-8 shadow-md inset-10 shadow-black md:w-1/3 mx-auto sm:w-1/2">
      <header className="flex justify-between items-center py-4 text-white">
        <h3 className="text-xl">{userInfo.name} Profile</h3>
      </header>
      <form onSubmit={submitHandler}>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Fragment>
            {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && (
              <MessageBox variant="danger">{errorUpdate}</MessageBox>
            )}
            {successUpdate && (
              <MessageBox variant="success">
                Profile Updated Successfully
              </MessageBox>
            )}
            <div>
              <label
                htmlFor="name"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Name
              </label>

              <input
                id="name"
                type="text"
                className="form-control   form-control:hover form-control:focus"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            <div>
              <label
                htmlFor="email"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Email address
              </label>

              <input
                id="email"
                type="email"
                placeholder="Enter email"
                className="form-control   form-control:hover form-control:focus"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <label
              htmlFor="password"
              className="text-sm block font-bold mb-2 text-gray-400"
            >
              Password
            </label>

            <input
              id="password"
              type="password"
              className="form-control   form-control:hover form-control:focus"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            ></input>

            <label
              htmlFor="confirmPassword"
              className="text-sm block font-bold mb-2 text-gray-400 "
            >
              Confirm Password
            </label>

            <input
              id="confirmPassword"
              type="password"
              className="form-control   form-control:hover form-control:focus"
              placeholder="Enter confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
            <button
              className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
              type="submit"
            >
              Update
            </button>
          </Fragment>
        )}
      </form>
   
    </div>
  );
}
