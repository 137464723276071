import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./components/App";
import "typeface-quicksand";

import store from "./store";
const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
  
      <App />
 
    </Provider>
);


