import React, { useContext, useEffect, useState , useTransition} from "react";
import DrawingContext from "../../context/drawing/drawingContext";
import DrawingList from "../Drawings/DrawingList";
import { useSelector } from "react-redux";
import { VscEmptyWindow } from "react-icons/vsc";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";
const DrawingsPage = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const drawingContext = useContext(DrawingContext);

  const { drawings, getDrawings,  error } = drawingContext;
  const [selectedOption, setSelectedOption] = useState("");
  const [isPending, startTransition]= useTransition();
  useEffect(() => {
    startTransition(()=>{ getDrawings(userInfo._id);});

    // eslint-disable-next-line
  }, [setSelectedOption]);

  const Options = ({ options }) => {
    return options.map((option, index) => (
      <option className="optionListItem" key={index} value={option._id}>
        {option.title}
      </option>
    ));
  };

  const renderDrawings = () => {
    if (drawings === null)
      return (
        <div className="flex flex-col justify-center items-center">
          <VscEmptyWindow className="w-48 h-48 text-white" />
        </div>
      );
    if (drawings.length === 0 && drawings != null && !isPending)
      return (
        <div className="flex flex-col justify-center items-center">
          <LoadingBox></LoadingBox>
        </div>
      );

    return (
      <div className="bg-zinc-800 px-5  shadow-md shadow-black min-w-400">
        <header className="flex justify-between items-center py-6">
          {isPending ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox>{error}</MessageBox>
          ) : (
            <div>
              <h5 className="text-md text-gray-300 ">
                {drawings && (
                  <p>
                    {userInfo.name} , you have {drawings.length} ..., these are
                    your drawings:
                  </p>
                )}
              </h5>
              <select
                className=" mt-2 origin-top-right rounded-sm bg-blue-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                value={selectedOption}
                key={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option className="optionListItem">
                  Seleccione por título
                </option>
                <Options options={drawings} />
              </select>
            </div>
          )}
        </header>

        {selectedOption && (
          <DrawingList drawings={drawings} selectedOption={selectedOption} />
        )}
      </div>
    );
  };

  return (
    <main>
    <header className="flex justify-between items-center my-4">
        <h1 className="text-2xl text-gray-300 font-bold">
          Drawings ({drawings.length})
        </h1>
      </header>
  
      {renderDrawings()}
    </main>
  );
};

export default DrawingsPage;
