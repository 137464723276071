
import React, { useContext, useEffect, useTransition } from "react";
import PostContext from "../context/post/postContext";
import { PostCard2 } from "../components/posts/PostCard2";
import LoadingBox from "../components/LoadingBox";
import { VscEmptyWindow } from "react-icons/vsc";
import MessageBox from "../components/MessageBox";
const HomeScreen = () => {
  const postContext = useContext(PostContext);
  const { posts, loading, error, getPosts } = postContext;
  const [isPending, startTransition]= useTransition();
  useEffect(() => {
    startTransition(()=>{getPosts();})
   

    // eslint-disable-next-line
  }, []);
  if (posts !== null && posts.length === 0 && !loading) {
    return isPending ?(<div>Loading...</div>)
    : (
      <div className="bg-zinc-800 p-20 mt-8 shadow-md shadow-black -inset-40 block min-w-400">
        <h1 className="text-white text-2xl ">
         No hay posts
        </h1>
        <VscEmptyWindow className="w-48 h-48 text-white mx-auto mt-5" />
      </div>
    );
  }
  return (
    <div className=" flex justify-between items-center py-5 px-2 ">
    {loading ? (
      <LoadingBox></LoadingBox>
    ) : error ? (
      <MessageBox>{error}</MessageBox>
    ) : (
     
   
      <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
          {posts &&
            posts.map((post) => <PostCard2 key={post._id} post={post} />)}
        </div>
    
    )}
  </div>
   
  );
};
export default HomeScreen;