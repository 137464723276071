// ImageModal.tsx

import React, { useRef } from "react";

const ModalImg = ({ imageUrl, onClose }) => {
  const modalRef = useRef(null);

/*   const closeModal = () => {
    onClose();
  }; */

  // Agregar un event listener para cerrar el modal al hacer clic fuera de él
  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-90 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div
        ref={modalRef}
        className="relative bg-white max-w-screen-lg border border-zinc-700 "
      >
        <button
          onClick={() =>  onClose()}
          className="absolute top-2 right-2 p-2 text-white bg-gray-500 rounded-full hover:bg-gray-600"
        >
          X
        </button>
        <img
          src={imageUrl}
          alt="Imagen en pantalla completa"
          className="min-h-fit "
        />
      </div>
    </div>
  );
};

export default ModalImg;
