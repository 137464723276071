import React, { useContext } from "react";
import TaskContext from "../../context/task/taskContext";

function TaskCard({ task }) {
  const taskContext = useContext(TaskContext);
  const { deleteTask, updateTask, clearCurrent } = taskContext;

  const onDelete = () => {
    deleteTask(task._id);
    clearCurrent();
  };
  const onhandleToggleDone = () => {
    updateTask(task);
  };

  return (
    <div className="bg-zinc-900 text-white p-3 rounded-md shadow-md shadow-black  ">
      <textarea
      type="text"
        disabled
        id="message"
        rows="4"
        className="block p-4 w-full text-sm text-white bg-blue-700   border border-blue-900   dark:text-white disabled "
        value={task.message}
        required
      >
        {task.name}
      </textarea>
      <h5 className="p-4">{task.done ? "Done" : "Not done"}</h5>
      <div className="flex justify-between items-center p-2">
        <button
          onClick={onDelete}
          className="bg-red-600 text-sm px-2 py-1 rounded hover:bg-red-400 hover:text-red-900"
        >
          Delete
        </button>
        <button
          onClick={onhandleToggleDone}
          className="bg-green-600 text-sm px-2 py-1 rounded hover:bg-green-400 hover:text-green-900"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default TaskCard;
