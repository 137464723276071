import {
   ADD_TASK,
   GET_TASKS_TODO,
   GET_TASKS_DONE,
   REMOVE_TASK,
   UPDATE_TASK,  
   CLEAR_TASKS, 
   CLEAR_CURRENT,
   TASK_ERROR,
   SET_CURRENT
 
} from '../types';
// eslint-disable-next-line
export default (state, action) => {
   switch (action.type) {
      case ADD_TASK:
         return {
            ...state,
            tasks: [action.payload, ...state.tasks],
            loading: false
         };
         case UPDATE_TASK:
            return {
               ...state,
               tasks: state.tasks
               .filter((task) =>task._id !== action.payload._id )
               .map(task =>
                  task._id === action.payload._id ? action.payload : task
                   
               ),
               loading: false
            };
      case GET_TASKS_TODO:
         return {
            ...state,
            tasks: action.payload,
            loading: false
         };
         case GET_TASKS_DONE:
         return {
            ...state,
            tasks: action.payload,
            loading: false
         };
         case CLEAR_TASKS:
            return {
               ...state,
               tasks: null,
               current: null,
               error: null
            };
         case SET_CURRENT:
            return {
               ...state,
               current: action.payload
            };
         case CLEAR_CURRENT:
            return {
               ...state,
               current: null
            };
      case REMOVE_TASK:
         return {
            ...state,
            tasks: state.tasks.filter(
               task => task._id !== action.payload
            ),
            loading: false
         };
 
         case TASK_ERROR:
            return {
               ...state,
               error: action.payload
            };
         default:
            return state;
      }
   };
   