import React , { useContext }from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import PostContext from "../../context/post/postContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useEffect, useState } from "react";

const PostForm=()=> {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const user = userInfo._id;
  const postContext = useContext(PostContext);
  const { addPost,  updatePost, getPost } = postContext;
  const navigate = useNavigate();
  const [post, setPost] = useState({
    title: "",
    description: "",
    image: null,
    userId:user,
  });
  const params = useParams();

  useEffect(() => {
    (async () => {
      if (params.id) {
        const post = await getPost(params.id);
        setPost({
          title: post.title,
          description: post.description,
          
        });
      }
    })();
  }, [params.id, getPost]);

  return (
    <div className="flex items-center  justify-center">
      <div className="bg-zinc-900 p-10 mt-8 shadow-md shadow-black">
        <header className="flex justify-between items-center py-4 text-white">
          <h3 className="text-xl">New Post</h3>
          <Link to="/posts" className="text-gray-400 text-sm hover:text-gray-300">
            Go Back
          </Link>
        </header>
        <Formik
          initialValues={post}
          enableReinitialize
          validationSchema={Yup.object({
            title: Yup.string().required("Title is Required"),
            description: Yup.string().required("Description is Required"),
             
          })}
          onSubmit={async (values, actions) => {
            console.log(values)
            if (params.id) {
              await updatePost(params.id, values);
            } else {
              const newPost = {
                title: values.title,
                description: values.description,
                image: values.image,
                userId:user,
              };
              await addPost(newPost);
              
            }
            actions.resetForm();
            actions.setSubmitting(false);
            navigate("/posts");
          }}
        >
          {({ setFieldValue, isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <label
                htmlFor="title"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Title
              </label>
              <Field
                className="form-control   form-control:hover form-control:focus"
                placeholder="Post title"
                name="title"
                // autoFocus
              />
              <ErrorMessage
                component="p"
                name="title"
                className="text-red-400 text-sm"
              />

              <label
                htmlFor="description"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Description
              </label>
              <Field
                component="textarea"
                name="description"
                id="description"
                placeholder="Write a description"
                rows="3"
                className="form-control   form-control:hover form-control:focus"
              />
              <ErrorMessage
                component="p"
                name="description"
                className="text-red-400 text-sm"
              />

              <label
                htmlFor="image"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Image
              </label>
              <input
                type="file"
                name="image"
                className="form-control   form-control:hover form-control:focus"
                onChange={(e) => setFieldValue("image", e.target.files[0])}
              />
              <ErrorMessage
                component="p"
                name="image"
                className="text-red-400 text-sm"
              />

              <button
                type="submit"
                className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto "
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 inset-20" />
                ) : (
                  "Save"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
export default PostForm;