import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navigation from "../Layout/Navigation";
import PostState from "../../context/post/PostState";
import DrawingState from "../../context/drawing/DrawingState";
import TaskState from "../../context/task/TaskState";
import Tasks from "../pages/Tasks";
import UploadPage from "../pages/Uploads";
import ContactForm from "../comments/ContactForm";
import PostList from "../posts/PostsList";
import DrawingsPage from "../pages/Drawings";
import ChatBox from "../pages/ChatBox";
import HomeScreen from "../../screens/HomeScreen";
import NotFoundPage from "../pages/NotFoundPage";
import CommentsList from "../comments/CommentsList";
import PostForm from "../posts/PostForm";
import GetImage from "../images/GetImage";
import SigninScreen from "../../screens/SigninScreen";
import ProfileScreen from "../../screens/ProfileScreen";
import Register from "../../screens/Register.js";
import SupportScreen from "../../screens/SupportScreen";
import PrivateRoute from "../../Routes/PrivateRoute";
import AdminRoute from "../../Routes/AdminRoute";
import UserListScreen from "../../screens/UserListScreen";
import UserEditScreen from "../../screens/UserEditScreen";
const App = (props) => {
  return (
    <div className="bg-zinc-800  text-white">
      <div className="flex items-center justify-center h-full ">
        <Router>
          <TaskState>
            <PostState>
              <DrawingState>
                <div className="fixed-top w-full z-1  ">
                  <Navigation />
                  <main className="min-h-screen ">
                    <Routes>
                      <Route path="/contactForm" element={<ContactForm />} />
                      <Route
                        path="/posts"
                        element={
                          <PrivateRoute>
                            <PostList />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <PrivateRoute>
                            <PostForm />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/tasks"
                        element={
                          <PrivateRoute>
                            <Tasks />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/:id"
                        element={
                          <PrivateRoute>
                            <PostForm />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <PrivateRoute>
                            <ProfileScreen />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/uploadimage"
                        element={
                          <PrivateRoute>
                            <UploadPage />
                          </PrivateRoute>
                        }
                      />
                      <Route path="/images/:id" element={<GetImage />} />
                      <Route
                        path="/support"
                        element={
                          <AdminRoute>
                            <SupportScreen />
                          </AdminRoute>
                        }
                      />
                      <Route
                        path="/user/:id/edit"
                        element={
                          <AdminRoute>
                            <UserEditScreen />
                          </AdminRoute>
                        }
                      />
                      <Route
                        path="/userlist"
                        element={
                          <AdminRoute>
                            <UserListScreen />
                          </AdminRoute>
                        }
                      />
                      <Route
                        path="/comments"
                        element={
                          <AdminRoute>
                            <CommentsList />
                          </AdminRoute>
                        }
                      />
                      <Route
                        path="/chat"
                        element={
                          <PrivateRoute>
                            <ChatBox />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/drawings"
                        element={
                          <PrivateRoute>
                            <DrawingsPage />
                          </PrivateRoute>
                        }
                      />
                      <Route path="/signin" element={<SigninScreen />} />
                      <Route
                        path="/register"
                        element={<Register {...props} />}
                      />
                       <Route path="/" element={<HomeScreen/>} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </main>
                </div>

                <Toaster />
              </DrawingState>
            </PostState>
          </TaskState>
        </Router>
      </div>
    </div>
  );
};

export default App;
