import React from "react";
import { useDispatch} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signin } from "../actions/userActions";

import { getError } from '../utils';
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function SigninScreen(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  return (
   
      <div className="bg-zinc-900 p-10 mt-8 shadow-md shadow-black min-w-400 md:w-1/3 mx-auto sm:w-1/2">
        <header className="flex justify-between items-center py-4 text-white">
          <h3 className="text-xl">Signin</h3>
        </header>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={Yup.object({
            email: Yup.string().required("Email is Required").email(),
            password: Yup.string().required("Password is Required"),
          })}
          onSubmit={async (values, actions) => {
            try {
              dispatch(signin(values.email, values.password));
            } catch (err) {
              toast.error(getError(err));
            }
            actions.resetForm();
            actions.setSubmitting(false);
            navigate("/");
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
      
              <label
                htmlFor="email"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Email address
              </label>
              <Field
                className="form-control   form-control:hover form-control:focus"
                placeholder="Write an email"
                name="email"
                // autoFocus
              />
              <ErrorMessage
                component="p"
                name="email"
                className="text-blue-600 text-sm"
              />

              <label
                htmlFor="password"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Password
              </label>
              <Field
                name="password"
                id="password"
                placeholder="Write a password"
                className="form-control   form-control:hover form-control:focus"
              />
              <ErrorMessage
                component="p"
                name="password"
                className="text-blue-600 text-sm"
              />

    
              <button
                type="submit"
                className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                ) : (
                  "Send"
                )}
              </button>

              <div className="m-5">
                <label className="text-sm block font-bold mb-2 text-gray-400">
                  New customer?{" "}
                </label>

                <Link
                   className="text-sm block font-bold mb-2 text-blue-600 hover:underline"
                  to={`/register?navigate=${navigate}`}
                >
                  Create your account
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
  
  );
}
