import { useState } from "react";
import ModalImg from "./ModalImg";

export function PostCard2({ post }) {
  const [isModalImgOpen, setModalImgOpen] = useState(false);
  const closeModalImg = () => {
    setModalImgOpen(false);
  };
  const openModalImg = () => {
    setModalImgOpen(true);
  };

  return (
    <div className=" min-w-300  w-full bg-zinc-800  max-w-xs sm:max-w-xs  mx-auto border border-zinc-700 shadow-md shadow-black">
      {post.image && (
   
          <div onClick={openModalImg} className="cursor-pointer">
            <img src={post.image.url} alt={post.title} />
          </div>
)}
          <div className="items-center justify-between flex m-4">
            <div className="w-[530px] flex flex-col  leading-[1.5]">
              <h2 className="text-md font-semibold">{post.title}</h2>
              <p>{post.description}</p>
            </div>
          </div>
        
      
      {isModalImgOpen && (
        <ModalImg imageUrl={post.image.url} onClose={closeModalImg} />
      )}
    </div>
  );
}
