import React from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import { getError } from "../../utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import * as api from "../../api";
const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="text-area" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
const ContactForm = () => {
  const navigate = useNavigate();

  /*  const [comment, setComment] = useState({
    postername: "",
    message: "",
    date: new Date(),
    email: "",
    topic: "",
  }); */

  return (
    <div className="bg-zinc-900 p-10 mt-8 shadow-md shadow-black md:w-1/3 mx-auto sm:w-1/2">
      <header className="flex justify-between items-center py-4 text-white">
        <h3 className="text-xl">Leave your message:</h3>
      </header>
      <Formik
        initialValues={{
          postername: "",
          email: "",
          topic: "",
          message: "",
          date: new Date(),
        }}
        enableReinitialize
        validationSchema={Yup.object({
          postername: Yup.string().required("Name is Required"),
          email: Yup.string().required("Email is Required").email(),
          topic: Yup.string().required("Topic is Required"),
          message: Yup.string().required("Message is Required"),
        })}
        onSubmit={async (values, actions) => {
          try {
            console.log(values);
            await api.createComment(values);
            navigate("/");
          } catch (err) {
            toast.error(getError(err));
          }
          actions.resetForm();
          actions.setSubmitting(false);
          navigate("/");
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <label
              htmlFor="postername"
              className="text-sm block font-bold mb-2 text-gray-400"
            >
              Name
            </label>
            <Field
              className="form-control   form-control:hover form-control:focus"
              placeholder="Write a Name"
              name="postername"
              id="postername"
            />
            <ErrorMessage
              component="p"
              name="postername"
              className="text-blue-600 text-sm"
            />
            <label
              htmlFor="email"
              className="text-sm block font-bold mb-2 text-gray-400"
            >
              Email address
            </label>
            <Field
              className="form-control   form-control:hover form-control:focus"
              placeholder="Write an email"
              id="email"
              name="email"
              required
              // autoFocus
            />
            <ErrorMessage
              component="p"
              name="email"
              className="text-blue-600 text-sm"
            />
            <label
              htmlFor="topic"
              className="text-sm block font-bold mb-2 text-gray-400"
            >
              Topic
            </label>
            <Field
              className="form-control   form-control:hover form-control:focus"
              placeholder="Write a Topic"
              id="topic"
              name="topic"
              required
            />
            <ErrorMessage
              component="p"
              name="topic"
              className="text-blue-600 text-sm"
            />
            <label
              htmlFor="message"
              className="text-sm block font-bold mb-2 text-gray-400"
            >
              Message
            </label>
            <MyTextArea
              className="form-control   form-control:hover form-control:focus"
              placeholder="Write a Message"
              rows="3"
              id="message"
              name="message"
            />
            <ErrorMessage
              component="p"
              name="message"
              className="text-blue-600 text-sm"
            />

            <button
              type="submit"
              className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
              ) : (
                "Send"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ContactForm;
