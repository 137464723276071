import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import CanvasImageBackground from "./CanvasImageBackground";
;
const GetImage = (props) => {
  const params = useParams();
  console.log(params.id);
  const [currentUrl, setCurrentUrl] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [imageHeight, setImageHeight] = useState("");
  const imageId = params.id;

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const res = await api.getImage(imageId);
      console.log('path',res.data.path);
     console.log(res.data.width, res.data.height);
      setCurrentUrl(res.data.path);     
      setImageWidth(res.data.width);
      setImageHeight(res.data.height);
      console.log('ancho', imageWidth );
      console.log('alto',imageHeight )
      // ...
    }
    fetchData();
  }, [imageId,imageWidth,imageHeight ]);

  return currentUrl ? (
    <div className="bg-zinc-800 p-10 mt-5 shadow-md shadow-black min-w-400">

         {imageWidth && imageHeight && (<CanvasImageBackground imgSrc={currentUrl} imageWidth={imageWidth} imageHeight={imageHeight} />)}
    </div>
  ) : null;
};

export default GetImage;
