import React, { useContext } from "react";
import TaskContext from "../../context/task/taskContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getError } from "../../utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
const TaskForm = ({ history }) => {
  const navigate = useNavigate();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const user = userInfo._id;
  const taskContext = useContext(TaskContext);
  const { addTask, current, clearCurrent } = taskContext;

  const clearAll = () => {
    clearCurrent();
    navigate("/tasks");
  };

  return (
    <div className="bg-zinc-900 m-1  shadow-md shadow-black min-w-400 p-5">   
        <Formik
          initialValues={{
            name: "",
            userId: "",
          }}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Task is required"),
          })}
          onSubmit={async (values, actions) => {
            const newTask = {
              name: values.name,
              userId: user,
            };
            try {
              if (current === null) {
                addTask(newTask);
                clearAll();
              }
            } catch (err) {
              toast.error(getError(err));
            }
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                component="textarea"
                name="name"
                id="name"
                rows="3"
                placeholder="Write a task to do"
                className="form-control   form-control:hover form-control:focus"
              />
              <ErrorMessage
                component="p"
                name="name"
                className="text-blue-600 text-sm"
              />
              <button
                type="submit"
                className="bg-yellow-700 hover:bg-yellow-600  btn text-white focus:outline-none disabled:bg-blue-400 ml-auto"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                ) : (
                  "Add"
                )}
              </button>
            </Form>
          )}
        </Formik>
   
    </div>
  );
};

export default TaskForm;
