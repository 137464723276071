import React, { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { useSelector } from "react-redux";

const ENDPOINT =
  window.location.host.indexOf("localhost") >= 0
    ? "https://www.qepbits.es"
    : window.location.host;

export default function ChatBox() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [socket, setSocket] = useState(null);
  const uiMessagesRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [messageBody, setMessageBody] = useState("");
  const [messages, setMessages] = useState([
    { name: "Admin", body: "Hello there, Please ask your question." },
  ]);

  useEffect(() => {
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.clientHeight,
        left: 0,
        behavior: "smooth",
      });
    }
    if (socket) {
      socket.emit("onLogin", {
        _id: userInfo._id,
        name: userInfo.name,
        isAdmin: userInfo.isAdmin,
      });
      socket.on("message", (data) => {
        setMessages([...messages, { body: data.body, name: data.name }]);
      });
    }
    // eslint-disable-next-line
  }, [messages, isOpen, socket, userInfo._id]);

  const supportHandler = () => {
    setIsOpen(true);
    console.log(ENDPOINT);
    const sk = socketIOClient(ENDPOINT);
    setSocket(sk);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (!messageBody.trim()) {
      alert("Error. Please type message.");
    } else {
      setMessages([...messages, { body: messageBody, name: userInfo.name }]);
      setMessageBody("");
      setTimeout(() => {
        socket.emit("onMessage", {
          body: messageBody,
          name: userInfo.name,
          isAdmin: userInfo.isAdmin,
          _id: userInfo._id,
        });
      }, 1000);
    }
  };
  const closeHandler = () => {
    setIsOpen(false);
  };
  return (
    <div className="bg-zinc-800 p-20 mt-8 w-full shadow-md shadow-black -inset-40 block min-w-400 md:w-1/2 mx-auto">
      {!isOpen ? (
         <header className="flex items-center ">
          <h4>{userInfo.name} , begin a chat with the Admin:  </h4>
        <button
          className="bg-red-600 hover:bg-red-500 px-4 py-2 ml-4  rounded  text-white focus:outline-none disabled:bg-blue-400 "
          onClick={supportHandler}
        >
          Support
        </button>
        </header>
      ) : (
        <div>
          <div className="columns-2">
            <button
              className="bg-yellow-600 hover:bg-yellow-500 px-7 py-2 mt-4  rounded  text-black focus:outline-none disabled:bg-blue-400 "
              onClick={closeHandler}
            >
              Close
            </button>
          </div>
          <ul ref={uiMessagesRef}>
            {messages.length === 0 && <li>No message.</li>}
            {messages.map((msg, index) => (
              <li
                key={index}
                className={`my-2 p-3 table text-sm rounded-sm ${
                  msg.name === userInfo.name
                    ? "bg-blue-900 ml-auto"
                    : "bg-black"
                }`}
              >
                <b>{msg.name}</b>:{' '}{msg.body}
              </li>
            ))}
          </ul>

          <form onSubmit={submitHandler}>
            <label for="chat" className="sr-only">
              Your message
            </label>
            <div className="flex items-center py-2 px-3 bg-zinc-900 shadow-md shadow-black -inset-40 rounded-sm dark:bg-zinc-700">
              <textarea
                id="chat"
                rows="1"
                className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-zinc-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-zinc-500"
                placeholder="Your message..."
                value={messageBody}
                onChange={(e) => setMessageBody(e.target.value)}
                autoFocus
              ></textarea>
              <button
                type="submit"
                className="inline-flex justify-center p-2 text-blue-200 rounded-full cursor-pointer dark:text-blue-200 dark:hover:bg-gray-500 shadow-md shadow-black -inset-40"
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 rotate-90"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
                <span className="sr-only">Send message</span>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
