import React, { useState, useEffect, useTransition} from "react";
import { Link } from "react-router-dom";
import * as api from "../../api";
const UploadPage = () => {
  const [images, setImages] = useState([]);
  const [imageRef, setImageRef] = useState("");
  async function fetchData() {
    const res = await api.getImages();
    console.log(res.data);

    setImages(res.data);
  }
  const [isPending, startTransition]= useTransition();
  useEffect(() => {
    startTransition(()=>{ fetchData();
    })
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (imageRef.files.length > 0) {
      const file = imageRef.files[0];
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "react_images");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/recicle/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      if (!res.ok) {
        throw new Error();
      }
      const image = await res.json();
      console.log("image", image);

      try {
        const newImage = {
          public_id: image.public_id,
          path: image.secure_url,
          originalname: image.original_filename,
          size: image.bytes,
          width: image.width,
          height: image.height,
        };

        console.log("newImage", newImage);
        console.log("newImage width", newImage.width);
        console.log("newImage heght", newImage.height);
        await api.createImage(newImage);
        setImageRef("");
        setImages([]);
      } catch (error) {
        if (error.response) {
          console.log(error);
          const { data } = error.response;
          setImages({ imageerror: data });
        }
      }
    }
    fetchData();
  };
  const onRemoveImage = async (id) => {
    await api.deleteImage(id);
    fetchData();
  };
  return isPending ?(<div>Loading...</div>)
  : (
    <div className="bg-zinc-800  m-4 shadow-md shadow-black min-w-400">
      <header className="flex  items-center  text-white">
        <form onSubmit={onSubmit}>
          <div className="flex bg-zinc-700  items-center  rounded-md w-full mb-4">
            <label
              className=" block font-bold ml-4 mr-2 text-gray-400"
              htmlFor="photoFile"
            >
              Select
            </label>
            <input
              ref={(ref) => setImageRef(ref)}
              type="file"
              className="form-control-file d-none rounded-md "
              id="photoFile"
            />
            <button
              type="submit"
              className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled"
            >
              Enviar
            </button>
          </div>
        </form>
      </header>

   
        <div className="grid sm:grid-cols-2  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5 ">
          {images &&
            images.map((image) => (
              <div
                className="bg-zinc-800 text-white p-1  rounded-md shadow-md shadow-black  "
                key={image._id}
              >
                <img
                  className="card-img-top "
                  alt="Uploaded file"
                  src={image.path}
                />

                <div className="flex justify-between items-center py-4">
                  <Link
                    className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled"
                    to={"/images/" + image._id}
                  >
                    To draw
                  </Link>
                  <button
                    className="btn btn-danger btn-danger:hover btn-danger:focus btn-danger:disabled "
                    type="button"
                    onClick={() => onRemoveImage(image._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
   
  );
};

export default UploadPage;
