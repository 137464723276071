import React ,  { useContext }from "react";
import toast from "react-hot-toast";
import PostContext from "../../context/post/postContext";
import { useNavigate } from "react-router-dom";

export function PostCard({ post }) {
  const postContext = useContext(PostContext);
  const { deletePost} = postContext;
  const navigate = useNavigate();

  const handleDelete = (id) => {
    toast(
      (t) => (
        <div>
          <p className="text-white">
            Do you want to delete <strong>{id}</strong>?
          </p>
          <div>
            <button
              className="bg-red-500 hover:bg-red-400 px-3 py-2 text-sm text-white rounded-sm mx-2"
              onClick={(e) => {
                deletePost(id);
                toast.dismiss(t.id);
                
              }}
            >
              Delete
            </button>
            <button
              className="bg-slate-400 hover:bg-slate-500 px-3 py-2 text-white rounded-sm mx-2"
              onClick={() => toast.dismiss(t.id)}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      {
        duration: "4000",
        style: {
          background: "#202020"
        }
      }
    );
  };
  return (
    <div
      className="bg-zinc-800 text-white   rounded-md shadow-md shadow-black hover:bg-zinc-700 hover:cursor-pointer "
      onClick={() => navigate(`/${post._id}`)}
    key={post._id}>
      {post.image && <img src={post.image.url} alt={post.title} />}
      <div className="px-4 py-5  ">
        <div className="flex justify-between items-center -inset-40">
          <h2 className="text-md font-semibold">{post.title}</h2>
          <button
              className="btn btn-danger btn-danger:hover btn-danger:focus btn-danger:disabled "
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(post._id);
              navigate("/posts");
            }}
          >
            Delete
          </button>
        </div>
        <p>{post.description}</p>
      </div>
      
    </div>
  );
}
