export const GET_PRODUCTS = 'GET_PRODUCTS';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const REMOVE_CURRENT = 'REMOVE_CURRENT';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET';
export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_REVIEW_CREATE_REQUEST = 'PRODUCT_REVIEW_CREATE_REQUEST';
export const PRODUCT_REVIEW_CREATE_SUCCESS = 'PRODUCT_REVIEW_CREATE_SUCCESS';
export const PRODUCT_REVIEW_CREATE_FAIL = 'PRODUCT_REVIEW_CREATE_FAIL';
export const PRODUCT_REVIEW_CREATE_RESET = 'PRODUCT_REVIEW_CREATE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCT_CATEGORY_LIST_REQUEST = 'PRODUCT_CATEGORY_LIST_REQUEST';
export const PRODUCT_CATEGORY_LIST_SUCCESS = 'PRODUCT_CATEGORY_LIST_SUCCESS';
export const PRODUCT_CATEGORY_LIST_FAIL = 'PRODUCT_CATEGORY_LIST_FAIL';
