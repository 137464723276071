import {
   ADD_POST,
   GET_POSTS,
   REMOVE_POST,
   UPDATE_POST,  
   CLEAR_POSTS, 
   CLEAR_CURRENT,
   POST_ERROR,
   SET_CURRENT
 
} from '../types';
// eslint-disable-next-line
export default (state, action) => {
   switch (action.type) {
      case ADD_POST:
         return {
            ...state,
            posts: [action.payload, ...state.posts],
            loading: false
         };
         case UPDATE_POST:
            return {
               ...state,
               posts: state.posts
               .filter((post) =>post._id !== action.payload._id )
               .map(post =>
                  post._id === action.payload._id ? action.payload : post
                   
               ),
               loading: false
            };
    
         case GET_POSTS:
         return {
            ...state,
            posts: action.payload,
            loading: false
         };
         case CLEAR_POSTS:
            return {
               ...state,
               posts: null,
               current: null,
               error: null
            };
         case SET_CURRENT:
            return {
               ...state,
               current: action.payload
            };
         case CLEAR_CURRENT:
            return {
               ...state,
               current: null
            };
      case REMOVE_POST:
         return {
            ...state,
            posts: state.posts.filter(
               post => post._id !== action.payload
            ),
            loading: false
         };
 
         case POST_ERROR:
            return {
               ...state,
               error: action.payload
            };
         default:
            return state;
      }
   };
   