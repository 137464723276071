import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser, updateUser } from "../actions/userActions";
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

import { USER_UPDATE_RESET } from "../constants/userConstants";

export default function UserEditScreen(props) {
  const navigate = useNavigate();
  const params = useParams();
  const { id: userId } = params;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const dispatch = useDispatch();
  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      navigate('/userlist');
    }
    if (!user) {
      dispatch(detailsUser(userId));
    } else {
      setName(user.name);
      setEmail(user.email);
      setIsAdmin(user.isAdmin);
    }
  }, [dispatch, navigate, successUpdate, user, userId]);

  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch update user
    dispatch(updateUser({ _id: userId, name, email,  isAdmin }));
  };

  return (
    <div className="bg-zinc-800 p-10 mt-8 shadow-md shadow-black min-w-400 md:w-1/3 mx-auto">
    <header className="flex justify-between items-center py-4 text-white">
        <h3 className="text-xl">Edit User </h3>
      </header>

          <form onSubmit={submitHandler}>
            {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && (
              <MessageBox variant="danger">{errorUpdate}</MessageBox>
            )}

            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <Fragment>
                <label
                  htmlFor="name"
                  className="text-sm block font-bold mb-2 text-gray-400"
                >
                  Name
                </label>
             
   <input
                id="name"
                className="form-control   form-control:hover form-control:focus"
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
                <label
                  htmlFor="email"
                  className="text-sm block font-bold mb-2 text-gray-400"
                >
                  Email address
                </label>
           
                      <input
                id="email"
                type="email"
                placeholder="Enter email"
                className="form-control   form-control:hover form-control:focus"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>

                <label
                  htmlFor="isAdmin"
                  className="text-sm font-bold mb-2 text-gray-400"
                >
                  Is Admin
                </label>
              
                 <input
                id="isAdmin"
                type="checkbox"
                className="text-red-400 text-sm block"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></input>
                 <div>
              <button type="submit" className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ">
                Update
              </button>
            </div>
                
              </Fragment>
            )}
          </form>
      
    </div>
  );
}
