import React, { useContext, useEffect, useTransition } from "react";
import PostContext from "../../context/post/postContext";
import { PostCard } from "./PostCard";
import { VscEmptyWindow } from "react-icons/vsc";
import { useSelector } from "react-redux";
import LoadingBox from "../LoadingBox";
import MessageBox from "../MessageBox";
const PostList = () => {
  const postContext = useContext(PostContext);

  const { getPostsByUser , posts, loading, error } = postContext;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [isPending, startTransition]= useTransition();
  useEffect(() => {
    startTransition(()=>{  getPostsByUser (userInfo._id);
    })

    // eslint-disable-next-line
  }, []);
  if (posts !== null && posts.length === 0 && !loading) {
    return isPending ?(<div>Loading...</div>)
    : (
      <div className="bg-zinc-800 p-20 mt-8 shadow-md shadow-black -inset-40 block min-w-400">
        <h1 className="text-white text-2xl ">
          {userInfo.name} , you don't have posts yet ...
        </h1>
        <VscEmptyWindow className="w-48 h-48 text-white mx-auto mt-5" />
      </div>
    );
  }

  return (
    <div className="bg-zinc-800 mt-4 shadow-md shadow-black min-w-400">
      <header className="flex justify-between items-center my-4">
        <h4>
          {posts !== null && (
            <p>
              {userInfo.name} , you have {posts.length} ...
            </p>
          )}
        </h4>
      </header>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox>{error}</MessageBox>
      ) : (
        <div className="flex justify-between items-center py-5 px-2 ">
          <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
            {posts &&
              posts.map((post) => <PostCard key={post._id} post={post} />)}
          </div>
        </div>
      )}
    </div>
  );
};
export default PostList;
