import React from "react";
import { VscEmptyWindow } from "react-icons/vsc";
const NotFoundPage = () => {
  return (
  
    <div className="flex flex-col justify-center items-center w-screen">
    <VscEmptyWindow className="w-48 h-48 text-white" />
    <h1 className="text-white text-2xl">Page not found</h1>
  </div>
   
  );
};
export default NotFoundPage;
