import React, { useEffect, useState } from "react";
import * as api from "../../api";
import { VscEmptyWindow } from "react-icons/vsc";
import toast from "react-hot-toast";

const CommentsList = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);

  const getComments = async () => {
    setLoading(true);
    const res = await api.getComments();
    setComments(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getComments();
  }, []);
  const onDelete = async (id) => {
    await api.deleteComment(id);
    getComments();
  };
  const handleDelete = (id) => {
    toast(
      (t) => (
        <div>
          <p className="text-white">Do you want to delete ?</p>
          <div>
            <button
              className="bg-red-500 hover:bg-red-400 px-3 py-2 text-sm text-white rounded-sm mx-2"
              onClick={(e) => {
                onDelete(id);
                toast.dismiss(t.id);
              }}
            >
              Delete
            </button>
            <button
              className="bg-slate-400 hover:bg-slate-500 px-3 py-2 text-white rounded-sm mx-2"
              onClick={() => toast.dismiss(t.id)}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      {
        duration: "4000",
        style: {
          background: "#202020",
        },
      }
    );
  };
  const renderComment = () => {
    if (!loading && comments.length === 0)
      return (
        <div className="flex flex-col justify-center items-center w-screen">
          <VscEmptyWindow className="w-48 h-48 text-white" />
          <h1 className="text-white text-2xl">There are no comments</h1>
        </div>
      );

    return (
      <div className="flex justify-between items-center py-5 px-2 ">
      <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
        {comments.map((comment) => (
        
            <div
              className=" text-white  rounded-sm shadow-sm shadow-black overflow-hidden " key={comment._id}
              /*  onClick={() => navigate(`/${comment._id}`)} */
            >
              <div className="border border-gray-900 shadow-md bg-zinc-800">
                <ul className="divide-y divide-zinc-900 space-y-3 ">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm " >
                  <span> de:</span>
                    <h1 className="mt-1    sm:mt-0 ">
                    {comment.postername}
                    </h1>
                    <button
                      className="bg-red-600 text-sm px-2 py-1 rounded-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(comment._id);
                      }}
                    >
                      Delete
                    </button>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <p className="mt-1 text-sm   sm:mt-0 "> {comment.email}</p>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <span className="mt-1 text-sm   sm:mt-0 sm:col-span-2">
                      {comment.topic}
                    </span>
                  </li>

                  <li className=" p-1  border border-gray-900 shadow-md dark:bg-zinc-800 dark:border-zinc-900">
                  <textarea  disabled id="message" rows="4" value={comment.message} className="block p-4 w-full text-sm text-white bg-green-800   border border-gray-900  dark:bg-zinc-700 dark:border-zinc-800  dark:text-white disabled "   >
                      {comment.message}
                      </textarea>
                  </li>
                </ul>
              </div>
          </div>
         
        ))}
      </div>
      </div>
    );
    
  };

  return (
    <div className="w-4/10">
      <header className="flex justify-between items-center my-4">
        <h1 className="text-2xl text-gray-300 font-bold">
          Comments ({comments.length})
        </h1>
      </header>
      {renderComment()}
    </div>
  );
};
export default CommentsList;
