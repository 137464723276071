import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signout } from "../../actions/userActions";

const SideDrawer = (props) => {
  const { show } = props;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();

  const authLinks = (
    <div className="py-4 ">
      {userInfo && userInfo.isAdmin ? (
        <ul className="space-y-4">
          <li>
            <NavLink
              className="text-green-700 hover:bg-green-700 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
              to="/profile"
            >
              Admin, {userInfo.name}
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-yellow-400 hover:bg-yellow-400  hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
              to="/support"
            >
              Support
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-red-600 hover:bg-red-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
              to="/comments"
            >
              <i className="fas fa-envelope" /> Comments
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-purple-600 hover:bg-purple-600  hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
              to="/userlist"
            >
              <i className="fas fa-users" /> Users
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#!"
              onClick={() => dispatch(signout())}
              className=" hover:text-blue-200  text-white  text-sm px-2 py-2 rounded-sm   hover:bg-blue-700 "
            >
              Sign Out
            </NavLink>
          </li>
        </ul>
      ) : (
        userInfo && (
          <ul className="space-y-4">
            <li>
              <NavLink
                className="text-green-700 hover:bg-green-700 px-3 py-2 rounded-sm text-sm font-medium hover:text-zinc-900"
                aria-current="page"
                to="/profile"
              >
                Hello, {userInfo.name}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-red-600 hover:bg-red-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/posts"
              >
                Posts
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-purple-600 hover:bg-purple-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/new"
              >
                Add posts
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-blue-600 hover:bg-blue-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/tasks"
              >
                Tasks
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-indigo-600 hover:bg-indigo-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/drawings"
              >
                Drawings
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-fuchsia-400 hover:bg-fuchsia-400 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/uploadimage"
              >
                To draw
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-yellow-400 hover:bg-yellow-400 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/chat"
              >
                Chat
              </NavLink>
            </li>
            <li>
              <NavLink
                to="#!"
                onClick={() => dispatch(signout())}
                className=" hover:text-pink-200  text-white  text-sm px-2 py-2 rounded-sm   hover:bg-pink-700 "
              >
                Sign Out
              </NavLink>
            </li>
          </ul>
        )
      )}
    </div>
  );
  const guestLinks = (
    <ul className="space-y-4">
      <li>
        <NavLink
          className="text-yellow-400 hover:bg-yellow-400 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
          to="/contactForm"
        >
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink
          className="text-red-600 hover:bg-red-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
          to="/signin"
        >
          Sign In
        </NavLink>
      </li>
      <li>
        <NavLink
          className="text-green-700 hover:bg-green-700 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
          to="/register"
        >
          Sign Up
        </NavLink>
      </li>
    </ul>
  );
  return (
    <div
      data-drawer-backdrop="true"
      className={` top-14 left-0 w-40 absolute  p-4 md:hidden dark:bg-zinc-800  bg-zinc-800 dark:text-white   h-auto z-3  ease-in-out duration-300 ${
        show ? "translate-x-0 " : "-translate-x-full "
      }`}
      tabIndex="-1"
      aria-labelledby="drawer-navigation-label"
    >
      {userInfo ? authLinks : guestLinks}
      {false && <Link to="/" />}
    </div>
  );
};

export default SideDrawer;
