import React, { useContext, useEffect, useTransition } from "react";
import TaskContext from "../../context/task/taskContext";
import { useSelector } from "react-redux";
import TaskCard from "./TaskCard";
import { VscEmptyWindow } from "react-icons/vsc";
function TasksList({ doneState = false }) {
  const { getTasksToDo, loading, getTasksDone, tasks } =
    useContext(TaskContext);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const [isPending, startTransition]= useTransition();
  useEffect(() => {
    startTransition(()=>{
    if (doneState) {
      getTasksToDo(userInfo._id);
    } else {
      getTasksDone(userInfo._id);
    }
  })
    // eslint-disable-next-line
  }, [doneState]);
  if (tasks !== null && tasks.length === 0 && !loading) {
    return (
      <div className="flex flex-col justify-center items-center">
        <VscEmptyWindow className="w-48 h-48 text-white" />
      </div>
    );
  }
  return isPending ?(<div>Loading...</div>)
  : (
    <div className="pl-2">
      <header className="flex justify-between items-center ">
        <h5 className="text-md text-gray-300 ">
          {tasks !== null && (
            <p>
              {userInfo.name} , you have {tasks.length} ...
            </p>
          )}
        </h5>
      </header>

      {tasks !== null && !loading ? (
     
          <div className="flex justify-between items-center py-5 px-2 ">
            <div className="grid  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 w-full">
              {tasks &&
                tasks.map((task) => (
                 
                    <TaskCard key={task._id} task={task} />
                 
                ))}
            </div>
          </div>
       
      ) : (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}
export default TasksList;
