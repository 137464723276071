import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { detailsUser, deleteUser } from "../actions/userActions";
import { useNavigate, useParams } from "react-router-dom";

import { USER_UPDATE_RESET } from "../constants/userConstants";

export default function UserCard({ user }) {
  const navigate = useNavigate();
  const params = useParams();
  const { id: userId } = params;
  const userUpdate = useSelector((state) => state.userUpdate);
  const { success: successUpdate } = userUpdate;

  const dispatch = useDispatch();

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      navigate("/userlist");
    }
    if (!user) {
      dispatch(detailsUser(userId));
    }
  }, [dispatch, navigate, successUpdate, user, userId]);
  const deleteHandler = (user) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteUser(user._id));
    }
  };
  return (
    <div className="bg-zinc-800   rounded-md shadow-md shadow-black  ">
      <h2 className="p-4">{user.name}</h2>

      <h5 className="p-4"> {user.email}</h5>

      <h6>
        <span className="p-4"> is Admin:</span>
        {user.isAdmin ? "YES" : "NO"}
      </h6>
      <div className="flex justify-between items-center p-2">
        <button
          className="font-medium text-blue-700 m-1 dark:text-blue-700 hover:underline"
          onClick={() => navigate(`/user/${user._id}/edit`)}
        >
          Edit
        </button>
        <button
          className="font-medium m-1 text-red-600 dark:text-red-500 hover:underline"
          onClick={() => deleteHandler(user)}
        >
          Delete
        </button>
      </div>
    </div>
  );
}
