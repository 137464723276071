import React, { useRef, useState, useContext } from "react";
import CanvasDraw from "react-canvas-draw";
import DrawingContext from "../../context/drawing/drawingContext";
import { useSelector } from "react-redux";
import ColorPicker from "./ColorPicker";
import "./styles.css";
function CanvasImageBackground(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const user = userInfo._id;
  const {  imgSrc } = props;
  const [imageWidth, setImageWidth] = useState(props.imageWidth);
  const [imageHeight, setImageHeight]  = useState(props.imageHeight); 
  const [brush, setThick] = useState(50);
  const [brushColor, setBrushColor] = useState("#444");
  const drawingContext = useContext(DrawingContext);
  const { addDrawing } = drawingContext;
  const [title, setTitle] = useState("");
  const inputEl = useRef(null);
  const firstCanvas = useRef(null);
  const secondCanvas = useRef(null);


  if( imageWidth>800){

    setImageWidth(imageWidth*0.7)
    setImageHeight(imageHeight*0.7)

  }
  else if ( imageWidth>500&& imageWidth<800){
    
    setImageWidth(imageWidth*0.9)
    setImageHeight(imageHeight*0.9)
  }

  
  const handleChange = () => {
    inputEl.current.focus();
    //console.log(inputEl.current.value)
    setTitle(inputEl.current.value);
  };
  const onCreateImage = (event) => {
    const data = firstCanvas.current.getSaveData();
    event.preventDefault();
    if (title !== "") {
      const objDraw = JSON.parse(data);
      const newDrawing = [
        {
          title,
          height: imageHeight,
          width: imageWidth,
          lines: objDraw.lines,
          user,
        },
      ];
      addDrawing(newDrawing);
      secondCanvas.current.loadSaveData(data);
    }

    setTitle("");
  };
  const handleColorChange = React.useCallback((color) => {
    const {
      rgb: { r, g, b, a },
    } = color;
    setBrushColor(`rgba(${r}, ${g}, ${b},${a})`);
  }, []);

  const clear = () => {
    firstCanvas.current.clear();
  };
  const undo = () => {
    firstCanvas.current.undo();
  };

  return (
    <div className="bg-zinc-800 p-3 shadow-md shadow-black min-w-400">
      <form onSubmit={(event) => onCreateImage(event)}>
        <header className="container justify-between items-center py-2 text-white">
          <label className="text-sm   mb-2 ">Brush Thickness:</label>
          <input
            min="2"
            max="70"
            type="range"
            onChange={(event) => {
          
              setThick(event.target.value);
            }}
            className="px-3 py-2 focus:outline-none rounded bg-zinc-700 text-white w-full"
          />
          <button
            onClick={clear}
            className="bg-red-600 text-sm px-4 py-1 rounded-sm"
          >
            Clean
          </button>
          <button
            onClick={undo}
            className="bg-green-600 text-sm px-4 py-1 rounded-sm"
          >
            Undo
          </button>
          <label className="text-sm p-3  mb-2 ">Title:</label>
          <input
            ref={inputEl}
            type="text"
            onChange={handleChange}
            className="px-3 py-2 focus:outline-none rounded bg-zinc-700 text-white "
          />
          <button
            type="submit"
            className="bg-blue-600 text-sm m-2 px-4 py-2 rounded-sm"
          >
            Save
          </button>
        </header>
        <div className="grid sm:grid-cols-2  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <CanvasDraw
            lazyRadius={3}
            brushColor={brushColor}
            brushRadius={brush}
            hideGrid={false}
            gridColor="rgba(150,150,150,0.3)"
            style={{ border: "1px solid #000" }}
            canvasWidth={imageWidth}
              canvasHeight={imageHeight}
            getSaveData={onCreateImage}
            ref={firstCanvas}
            imgSrc={imgSrc}
          />

          <ColorPicker
            brushColor={brushColor}
            handleColorChange={handleColorChange}
          />
        </div>

        <CanvasDraw
          ref={secondCanvas}
          brushColor={brushColor}
          brushRadius={brush}
          disable={true}
          hideGrid={true}
          backgroundColor="transparent"
          canvasWidth={imageWidth}
          canvasHeight={imageHeight}
          style={{ border: "1px solid #000" }}
          imgSrc=""
        />
      </form>
    </div>
  );
}
export default CanvasImageBackground;
