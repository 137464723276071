import React from "react";

const backdrop = (props) => (
  <div
    onClick={props.click}
    className=" fixed  h-[150rem] top-15 left-0    w-screen bg-black/[.4] z-2"
    tabIndex="-1"
  />
);

export default backdrop;
