import React, { useContext, useRef } from "react";
import DrawingContext from "../../context/drawing/drawingContext";
import CanvasDraw from "react-canvas-draw";
//import { format } from 'timeago.js';
const DrawingItem = ({ drawing }) => {
  const drawingContext = useContext(DrawingContext);
  const { deleteDrawing } = drawingContext;
  const otherCanvas = useRef(null);
  const { _id, title } = drawing;

  const onDelete = () => {
    deleteDrawing(_id);
  };

  const onLoadDrawing = () => {
    const objDraw = JSON.stringify(drawing);
    otherCanvas.current.loadSaveData(objDraw);
  };

  return (

    <div className="bg-zinc-900 p-12 mt-3 shadow-md shadow-black min-w-400 container object-contain">
      <div className="flex items-center justify-end   ">
        <button
          className="bg-blue-600 hover:bg-blue-500 px-7 py-2   rounded  text-white focus:outline-none disabled:bg-blue-400 "
          onClick={onLoadDrawing}
        >
          Mostrar "{title}"
        </button>

        <button
          className="bg-red-600 hover:bg-red-500 px-3 py-2 text-sm text-white rounded-sm mx-2"
          onClick={onDelete}
        >
          Borrar
        </button>
      </div>
      <div className="container object-contain">
      <CanvasDraw
        ref={otherCanvas}
         //canvasWidth={309}
       
        disabled={true}
        canvasWidth={drawing.width*0.8}
        canvasHeight={drawing.height*0.8}
        brushRadius={0}
        lazyRadius={0}
        backgroundColor="transparent"
        hideGrid={true}
      />
      </div>
   </div>
  );
};

export default DrawingItem;
