import React, { useState } from "react";
import Nav from "./Nav.js";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideDrawer from "../SideDrawer/SideDrawer";
import { signout } from "../../actions/userActions";
import { Link } from "react-router-dom";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import Backdrop from "../Backdrop/Backdrop";
export default function Toolbar(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }
  const authLinks = (
    <div className=" flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
      {userInfo && userInfo.isAdmin ? (
        <Nav>
          <div className="hidden md:block md:ml-5">
            <div className="flex space-x-4">
              <NavLink
                className="text-green-700 hover:bg-green-700 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/profile"
              >
                Admin, {userInfo.name}
              </NavLink>

              <NavLink
                className="text-yellow-400 hover:bg-yellow-400  hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/support"
              >
                Support
              </NavLink>

              <NavLink
                className="text-red-600 hover:bg-red-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/comments"
              >
                <i className="fas fa-envelope" /> Comments
              </NavLink>

              <NavLink
                className="text-purple-600 hover:bg-purple-600  hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                to="/userlist"
              >
                <i className="fas fa-users" /> Users
              </NavLink>
            </div>
          </div>
        </Nav>
      ) : (
        userInfo && (
          <Nav>
            <div className="hidden md:block md:ml-6">
              <div className="flex space-x-4">
                <NavLink
                  className="text-green-700 hover:bg-green-700 px-3 py-2 rounded-sm text-sm font-medium hover:text-zinc-900"
                  aria-current="page"
                  to="/profile"
                >
                  Hello, {userInfo.name}
                </NavLink>
                <NavLink
                  className="text-yellow-400 hover:bg-yellow-400 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                  to="/chat"
                >
                  Chat
                </NavLink>
                <NavLink
                  className="text-red-600 hover:bg-red-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                  to="/posts"
                >
                  Posts
                </NavLink>
                <NavLink
                  className="text-purple-600 hover:bg-purple-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                  to="/new"
                >
                  Add posts
                </NavLink>
                <NavLink
                  className="text-blue-600 hover:bg-blue-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                  to="/tasks"
                >
                  Tasks
                </NavLink>
                <NavLink
                  className="text-indigo-600 hover:bg-indigo-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                  to="/drawings"
                >
                  Drawings
                </NavLink>
                <NavLink
                  className="text-fuchsia-600 hover:bg-fuchsia-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
                  to="/uploadimage"
                >
                  To draw
                </NavLink>
              </div>
            </div>
          </Nav>
        )
      )}
      <div className="hidden md:block md:ml-5 mt-4">
        <div className="flex space-x-4 ">
          <NavLink
            to="#!"
            onClick={() => dispatch(signout())}
            className=" hover:text-pink-200  text-white  text-sm px-2 py-2 rounded-sm   hover:bg-pink-700 "
          >
            Sign Out
          </NavLink>
        </div>
      </div>
    </div>
  );
  const guestLinks = (
    <Nav>
      <div className="hidden md:block md:ml-6">
        <div className="flex space-x-4">
          <NavLink
            className="text-yellow-400 hover:bg-yellow-400 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
            to="/contactForm"
          >
            Contact
          </NavLink>
          <NavLink
            className="text-red-600 hover:bg-red-600 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
            to="/signin"
          >
            Sign In
          </NavLink>

          <NavLink
            className="text-green-700 hover:bg-green-700 hover:text-zinc-900 px-3 py-2 rounded-sm text-sm font-medium"
            to="/register"
          >
            Sign Up
          </NavLink>
        </div>
      </div>
    </Nav>
  );

  return (
    <Nav>
      <div className=" absolute inset-y-1 left-0 flex items-center md:hidden z-1">
        <DrawerToggleButton click={() => setSideDrawerOpen(!sideDrawerOpen)} />
        {backdrop}
        <SideDrawer show={sideDrawerOpen} />
      </div>
      <div className=" flex  justify-tight  md:justify-start min-w-max  ">
        <Link className="flex-shrink-0 flex items-center ml-12" to="/">
          <img
            className="block  h-8 w-auto"
            src="img/logoxxxs.png"
            alt="Qepbits"
          ></img>
          <p className="text-blue-500 hover:text-blue-400  px-3 py-2 rounded-sm text-md font-medium">
            Qepbits.es
          </p>
        </Link>
      </div>
      <div className="flex-1 flex items-center justify-tight sm:items-stretch sm:justify-start md:justify-end min-w-max">
        <div className="divide-y divide-slate-100">
          {userInfo ? authLinks : guestLinks}
          {false && <Link to="/" />}
        </div>
      </div>
    </Nav>
  );
}
