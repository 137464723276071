import React from 'react';

export default function LoadingBox() {
  return (
    <div className="loading">
            <div className="w-12 h-12 rounded-full animate-spin absolute
                            border-8 border-solid border-purple-500 border-t-transparent"></div>
    </div>
  );
}
