import React, { useReducer } from "react";
import axios from "axios";
import TaskContext from "./taskContext";
import taskReducer from "./taskReducer";
import {
  ADD_TASK,
  REMOVE_TASK,
  TASK_ERROR,
  GET_TASKS_DONE,
  GET_TASKS_TODO,
  SET_CURRENT,
  UPDATE_TASK,
  CLEAR_TASKS,
  CLEAR_CURRENT,
} from "../types";

const TaskState = (props) => {
  const initialState = {
    tasks: null,
    current: null,
    error: null,
  };

  const [state, dispatch] = useReducer(taskReducer, initialState);

  const addTask = async (task) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post("/api/tasks", task, config);

      dispatch({ type: ADD_TASK, payload: res.data });
    } catch (error) {
      dispatch({ type: TASK_ERROR, payload: error.response.msg });
    }
  };

  const getTasksToDo = async (id) => {
    try {
      const res = await axios.get(`/api/tasks/user/${id}/notDone`);
      dispatch({ type: GET_TASKS_TODO, payload: res.data });
    } catch (error) {
      dispatch({ type: TASK_ERROR, payload: error.response.msg });
    }
  };
  const getTasksDone = async (id) => {
    try {
      const res = await axios.get(`/api/tasks/user/${id}/done`);
      dispatch({ type: GET_TASKS_DONE, payload: res.data });
    } catch (error) {
      dispatch({ type: TASK_ERROR, payload: error.response.msg });
    }
  };
  const setCurrent = (task) => {
    dispatch({ type: SET_CURRENT, payload: task });
  };
  const updateTask = async (task) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(`/api/tasks/${task._id}`, task, config);
      dispatch({ type: UPDATE_TASK, payload: res.data });
    } catch (error) {
      dispatch({ type: TASK_ERROR, payload: error.response.msg });
    }
  };

  const deleteTask = async (id) => {
    try {
      await axios.delete(`/api/tasks/${id}`);
      dispatch({ type: REMOVE_TASK, payload: id });
    } catch (error) {
      dispatch({ type: TASK_ERROR, payload: error.response.msg });
    }
  };

  const clearTasks = () => {
    dispatch({ type: CLEAR_TASKS });
  };

  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  return (
    <TaskContext.Provider
      value={{
        tasks: state.tasks,
        current: state.current,
        error: state.error,
        dispatch,
        addTask,
        getTasksToDo,
        deleteTask,
        updateTask,
        clearTasks,
        setCurrent,
        clearCurrent,
        getTasksDone,
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};

export default TaskState;
